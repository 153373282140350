export const ordersPageColumns = [
  {
    label: 'Symbol',
    formatter: 'symbol' /* StandardFormatterName.Symbol */,
    id: 'symbol',
    dataFields: ['symbol', 'symbol'],
  },
  {
    label: 'Side',
    id: 'side',
    dataFields: ['side'],
    formatter: 'side' /* StandardFormatterName.Side */,
  },
  {
    label: 'Type',
    id: 'type',
    dataFields: ['type', 'parentId', 'stopType'],
    formatter: 'type' /* StandardFormatterName.Type */,
  },
  {
    label: 'Lots',
    alignment: 'right',
    id: 'qty',
    dataFields: ['qty'],
    help: 'Size in lots',
    formatter: 'formatQuantity' /* StandardFormatterName.FormatQuantity */,
  },
  {
    label: 'Price',
    alignment: 'right',
    id: 'price',
    dataFields: ['price'],
    formatter: 'formatPrice' /* StandardFormatterName.FormatPrice */,
  },
  {
    label: 'Fill Price',
    alignment: 'right',
    id: 'fillPrice',
    dataFields: ['fillPrice'],
    formatter: 'formatPrice' /* StandardFormatterName.FormatPrice */,
  },
  {
    label: 'Take Profit',
    alignment: 'right',
    id: 'limitPrice',
    dataFields: ['limitPrice'],
    formatter: 'formatPrice' /* StandardFormatterName.FormatPrice */,
  },
  {
    label: 'Stop Loss',
    alignment: 'right',
    id: 'stopPrice',
    dataFields: ['stopPrice'],
    formatter: 'formatPrice' /* StandardFormatterName.FormatPrice */,
  },
  {
    label: 'Status',
    id: 'status',
    dataFields: ['status'],
    formatter: 'status' /* StandardFormatterName.Status */,
    supportedStatusFilters: [0 /* OrderStatusFilter.All */],
  },
  {
    label: 'Placing Time',
    id: 'createTime',
    dataFields: ['createTime'],
  },
  {
    label: 'Order',
    id: 'id',
    dataFields: ['id'],
  },
];
export const positionsPageColumns = [
  {
    label: 'Symbol',
    formatter: 'symbol' /* StandardFormatterName.Symbol */,
    id: 'symbol',
    dataFields: ['symbol', 'symbol'],
  },
  {
    label: 'Position',
    id: 'id',
    dataFields: ['id'],
  },
  {
    label: 'Time',
    alignment: 'left',
    id: 'time',
    dataFields: ['createTime'],
  },
  {
    label: 'Type',
    id: 'side',
    dataFields: ['side'],
    formatter: 'positionSide' /* StandardFormatterName.PositionSide */,
  },
  {
    label: 'Lots',
    alignment: 'right',
    id: 'qty',
    dataFields: ['qty'],
    help: 'Size in lots',
    formatter: 'formatQuantity' /* StandardFormatterName.FormatQuantity */,
  },
  {
    label: 'Avg Fill Price',
    alignment: 'right',
    id: 'avgPrice',
    dataFields: ['avgPrice'],
    formatter: 'formatPrice' /* StandardFormatterName.FormatPrice */,
  },
  {
    label: 'Take Profit',
    alignment: 'right',
    id: 'takeProfit',
    dataFields: ['takeProfit'],
    formatter: 'formatPrice' /* StandardFormatterName.FormatPrice */,
    highlightDiff: true,
  },
  {
    label: 'Stop Loss',
    alignment: 'right',
    id: 'stopLoss',
    dataFields: ['stopLoss'],
    formatter: 'formatPrice' /* StandardFormatterName.FormatPrice */,
    highlightDiff: true,
  },
  {
    label: 'Last Price',
    alignment: 'right',
    id: 'last',
    dataFields: ['last'],
    formatter:
      'formatPriceForexSup' /* StandardFormatterName.FormatPriceForexSup */,
    highlightDiff: true,
  },
  {
    label: 'Commission',
    alignment: 'right',
    id: 'commission',
    dataFields: ['commission'],
  },
  {
    label: 'Swap',
    alignment: 'right',
    id: 'swap',
    dataFields: ['swap'],
  },
  {
    label: 'P&L',
    alignment: 'right',
    id: 'pnl',
    dataFields: ['pnl'],
    formatter: 'profit' /* StandardFormatterName.Profit */,
  },
];
export const historyPageColumns = [
  {
    label: 'Symbol',
    formatter: 'symbol' /* StandardFormatterName.Symbol */,
    id: 'symbol',
    dataFields: ['symbol', 'symbol'],
  },
  {
    label: 'Side',
    id: 'side',
    dataFields: ['side'],
    formatter: 'side' /* StandardFormatterName.Side */,
  },
  {
    label: 'Type',
    id: 'type',
    dataFields: ['type', 'parentId', 'stopType'],
    formatter: 'type' /* StandardFormatterName.Type */,
  },
  {
    label: 'Lots',
    alignment: 'right',
    id: 'qty',
    dataFields: ['qty'],
    help: 'Size in lots',
    formatter: 'formatQuantity' /* StandardFormatterName.FormatQuantity */,
  },
  {
    label: 'Price',
    alignment: 'right',
    id: 'price',
    dataFields: ['price'],
    formatter: 'formatPrice' /* StandardFormatterName.FormatPrice */,
  },
  {
    label: 'Fill Price',
    alignment: 'right',
    id: 'fillPrice',
    dataFields: ['fillPrice'],
    formatter: 'formatPrice' /* StandardFormatterName.FormatPrice */,
  },
  {
    label: 'Status',
    id: 'status',
    dataFields: ['status'],
    formatter: 'status' /* StandardFormatterName.Status */,
    supportedStatusFilters: [0 /* OrderStatusFilter.All */],
  },
  {
    label: 'Commission',
    id: 'commission',
    dataFields: ['commission'],
  },
  {
    label: 'Placing Time',
    id: 'createTime',
    dataFields: ['createTime'],
  },
  {
    label: 'Closing Time',
    id: 'closingTime',
    dataFields: ['closingTime'],
  },
  {
    label: 'Order',
    id: 'id',
    dataFields: ['id'],
  },
];
export const accountHistoryPageColumns = [
  {
    label: 'Open Time',
    id: 'openTime',
    dataFields: ['openTime'],
  },
  {
    label: 'Symbol',
    formatter: 'symbol' /* StandardFormatterName.Symbol */,
    id: 'symbol',
    dataFields: ['symbol', 'symbol'],
  },
  {
    label: 'Position',
    id: 'positionId',
    dataFields: ['positionId'],
  },
  {
    label: 'Type',
    alignment: 'right',
    id: 'type',
    dataFields: ['type'],
  },
  {
    label: 'Lots',
    alignment: 'right',
    id: 'qty',
    dataFields: ['qty'],
    help: 'Size in lots',
    // formatter: 'formatQuantity' /* StandardFormatterName.FormatQuantity */,
  },
  {
    label: 'Price',
    alignment: 'right',
    id: 'openPrice',
    dataFields: ['openPrice'],
    formatter: 'formatPrice' /* StandardFormatterName.FormatPrice */,
  },
  {
    label: 'Take Profit',
    alignment: 'right',
    id: 'takeProfit',
    dataFields: ['takeProfit'],
    formatter: 'formatPrice' /* StandardFormatterName.FormatPrice */,
  },
  {
    label: 'Stop Loss',
    alignment: 'right',
    id: 'stopLoss',
    dataFields: ['stopLoss'],
    formatter: 'formatPrice' /* StandardFormatterName.FormatPrice */,
  },
  {
    label: 'Close Time',
    id: 'closeTime',
    dataFields: ['closeTime'],
  },
  {
    label: 'Price',
    alignment: 'right',
    id: 'closePrice',
    dataFields: ['closePrice'],
    formatter: 'formatPrice' /* StandardFormatterName.FormatPrice */,
  },
  {
    label: 'Commission',
    id: 'commission',
    dataFields: ['commission'],
  },
  {
    label: 'Swap',
    alignment: 'right',
    id: 'swap',
    dataFields: ['swap'],
  },
  {
    label: 'P&L',
    alignment: 'right',
    id: 'pnl',
    dataFields: ['pnl'],
    formatter: 'profit' /* StandardFormatterName.Profit */,
  },
];
