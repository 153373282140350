/**
 * @module Make sure that you include Promise polyfill in your bundle to support old browsers
 * @see {@link https://caniuse.com/#search=Promise | Browsers with native Promise support}
 * @see {@link https://www.npmjs.com/package/promise-polyfill | Polyfill}
 */
import {
  ordersPageColumns,
  positionsPageColumns,
  historyPageColumns,
  accountHistoryPageColumns,
} from './columns';
import { API_URL } from '../../src/const';
import {
  convertType,
  getSymbol,
  formatDateTimeWithTimezone,
  logErrorMessage,
  logMessage,
  getErrorMessage,
  generateUUID,
  hasTwoOrFewerDecimalPlaces,
  multiply,
  getcurrencySign,
} from '../../src/helpers';
import { io } from 'socket.io-client';

export class LuxBroker {
  constructor(host, quotesProvider) {
    this._accountManagerData = {
      title: 'Trading Sample',
      balance: '$ 0',
      equity: '$ 0',
      pl: '$ 0',
    };
    this._accountCurrency = 'USD';
    this._currencySign = '$';
    this.userLoggedIn = false;
    this.userID = '';
    this.token = '';
    this._positionById = {};
    this._positions = [];
    this._orderById = {};
    this._orders = [];
    this._cancelledBracketOrders = [];
    this._exchangeRate = {};
    this._positionIdAndPnlMap = new Map();
    this._quotesProvider = quotesProvider;
    this._host = host;
    this._currentUtcTimezone = 'Etc/UTC';
    this._host.setButtonDropdownActions(this._buttonDropdownItems());
    const sellBuyButtonsVisibility = this._host.sellBuyButtonsVisibility();
    if (sellBuyButtonsVisibility !== null) {
      sellBuyButtonsVisibility.subscribe(() => {
        this._host.setButtonDropdownActions(this._buttonDropdownItems());
      });
    }
    const domPanelVisibility = this._host.domPanelVisibility();
    if (domPanelVisibility) {
      domPanelVisibility.subscribe(() => {
        this._host.setButtonDropdownActions(this._buttonDropdownItems());
      });
    }
    const orderPanelVisibility = this._host.orderPanelVisibility();
    if (orderPanelVisibility) {
      orderPanelVisibility.subscribe(() => {
        this._host.setButtonDropdownActions(this._buttonDropdownItems());
      });
    }
    this._amChangeDelegate = this._host.factory.createDelegate();
    this._balanceValue = this._host.factory.createWatchedValue(
      this._accountManagerData.balance,
    );
    this._equityValue = this._host.factory.createWatchedValue(
      this._accountManagerData.equity,
    );
    this._plValue = this._host.factory.createWatchedValue(
      this._accountManagerData.pl,
    );

    this._ahChangeDelegate = this._host.factory.createDelegate();
  }

  _logoutAccountWhenForbidden() {
    window.logoutAccount();
    $('.btn-login').click();
  }

  _updateAccountInfo() {
    let pnl = 0;
    for (const [positionId, profitOrLoss] of this._positionIdAndPnlMap) {
      pnl += profitOrLoss + this._positionById[positionId].swap;
    }
    this._accountManagerData.pl = pnl.toFixed(2);
    this._accountManagerData.equity = (
      parseFloat(this._accountManagerData.balance) + pnl
    ).toFixed(2);
    this._balanceValue.setValue(
      `${this._currencySign} ${parseFloat(
        this._accountManagerData.balance,
      ).toFixed(2)}`,
    );
    this._equityValue.setValue(
      `${this._currencySign} ${this._accountManagerData.equity}`,
    );
    this._plValue.setValue(
      `${this._currencySign} ${this._accountManagerData.pl}`,
    );
    this._host.equityUpdate(this._accountManagerData.equity);
  }

  _createOrUpdateOrder(order) {
    this._orderById[order.id] = order;
    const oIndex = this._orders.findIndex((o) => o.id === order.id);
    if (oIndex !== -1) {
      this._orders.splice(oIndex, 1, order);
    } else {
      this._orders.push(order);
    }
    this._host.orderUpdate(order);
    if (
      this._cancelledBracketOrders.findIndex((id) => id === order.id) !== -1
    ) {
      this._host.showNotification(
        `Order ${order.id} ${order.status === 1 ? 'cancelled' : 'modified'}`,
        `${order.side === 1 ? 'BUY' : 'SELL'} ${order.qty} ${order.symbol} at ${
          order.type === 1
            ? order.limitPrice
            : order.type === 3
            ? order.stopPrice
            : order.price
        } ${
          order.type === 1
            ? '(LIMIT)'
            : order.type === 3
            ? '(STOP)'
            : '(MARKET)'
        }`,
        1,
      );
    }
    if (
      (order.id.indexOf('s') > 0 || order.id.indexOf('t') > 0) &&
      this._cancelledBracketOrders.findIndex((id) => id === order.id) === -1 &&
      order.status === 1
    ) {
      this._cancelledBracketOrders.push(order.id);
    }
  }

  _createOrUpdateBracketOrder(
    id,
    symbol,
    qty,
    side,
    stopLoss,
    takeProfit,
    status,
    createTime,
    fillPrice,
    closingTime,
  ) {
    let stopOrder = this._orderById[`${id}s`];
    let stopOrderUpdateRequired = true;
    if (stopOrder) {
      if (stopLoss) {
        if (fillPrice) {
          stopOrder.fillPrice = fillPrice;
          stopOrder.price = fillPrice;
          stopOrder.closingTime = closingTime;
          stopOrder.status = 2;
        } else {
          stopOrder.qty = qty;
          if (stopOrder.stopPrice === stopLoss && stopOrder.status === status)
            stopOrderUpdateRequired = false;
          stopOrder.stopPrice = stopLoss;
          stopOrder.status = status;
        }
      } else {
        if (stopOrder.status === 1) stopOrderUpdateRequired = false;
        stopOrder.status = 1;
      }
    } else if (stopLoss) {
      stopOrder = {
        id: `${id}s`,
        symbol: symbol,
        qty: qty,
        side: side === 1 ? -1 : 1,
        type: 3,
        stopPrice: stopLoss,
        status: status,
        parentType: 2,
        parentId: String(id),
        createTime: createTime,
      };
    }
    if (stopOrder && stopOrderUpdateRequired) {
      this._createOrUpdateOrder(stopOrder);
    }
    let limitOrder = this._orderById[`${id}t`];
    let limitOrderUpdateRequired = true;

    if (limitOrder) {
      if (takeProfit) {
        if (fillPrice) {
          limitOrder.fillPrice = fillPrice;
          limitOrder.price = fillPrice;
          limitOrder.closingTime = closingTime;
          limitOrder.status = 2;
        } else {
          limitOrder.qty = qty;
          if (
            limitOrder.limitPrice === takeProfit &&
            limitOrder.status === status
          )
            limitOrderUpdateRequired = false;
          limitOrder.limitPrice = takeProfit;
          limitOrder.status = status;
        }
      } else {
        if (limitOrder.status === 1) limitOrderUpdateRequired = false;
        limitOrder.status = 1;
      }
    } else if (takeProfit) {
      limitOrder = {
        id: `${id}t`,
        symbol: symbol,
        qty: qty,
        side: side === 1 ? -1 : 1,
        type: 1,
        limitPrice: takeProfit,
        status: status,
        parentType: 2,
        parentId: String(id),
        createTime: createTime,
      };
    }
    if (limitOrder && limitOrderUpdateRequired) {
      this._createOrUpdateOrder(limitOrder);
    }
  }

  _createOrUpdatePosition(position) {
    if (this._positionById[position.id]) {
      const sPosition = this._positionById[position.id];
      sPosition.qty = position.qty;
      sPosition.takeProfit = position.takeProfit;
      sPosition.stopLoss = position.stopLoss;
      sPosition.updateTime = position.updateTime;
      if (!isNaN(position.commission)) {
        sPosition.commission = `${this._currencySign} ${parseFloat(
          position.commission,
        ).toFixed(2)}`;
      }
      sPosition.swap = position.swap;
      this._host.positionUpdate(sPosition);
      this._positionById[position.id] = sPosition;
    } else {
      this._positionById[position.id] = position;
      this._positions.push(position);
      if (this._orderById[position.id]) {
        const order = this._orderById[position.id];
        order.status = 2;
        this._createOrUpdateOrder(order);
      }
      this._host.positionUpdate(position);
      this._host.plUpdate(position.pnl);
      logMessage(
        '_createOrUpdatePosition: subscribeQuotes started for position ',
        position.id,
      );
      const symbolInfo = getSymbol(position.symbol);
      const subscribe = new Promise((resolve) => {
        this._quotesProvider.subscribeQuotes(
          [position.symbol],
          [position.symbol],
          (quote) =>
            this._calculatePnlAndSetAccountInformation(
              quote,
              position.id,
              symbolInfo,
            ),
          `_position_${position.id}`,
        );
        resolve();
      });
    }
    this._createOrUpdateBracketOrder(
      position.id,
      position.symbol,
      position.qty,
      position.side,
      position.stopLoss,
      position.takeProfit,
      6,
      position.createTime,
    );
  }

  _calculatePnlAndSetAccountInformation(quote, positionId, symbolInfo) {
    const position = this._positionById[positionId];
    const quoteValue = quote[0].v;
    position.last = position.side === 1 ? quoteValue.bid : quoteValue.ask;
    position.pnl =
      (position.last - position.avgPrice) *
      (symbolInfo.contract_size ? symbolInfo.contract_size : 1) *
      position.qty *
      position.side;
    // Check if currency conversion is needed for pnl
    if (
      (this._accountCurrency !== 'USD' && !symbolInfo.currency_code) ||
      symbolInfo.currency_code
    ) {
      const baseCurrency = symbolInfo.currency_code || 'USD';
      //Get the conversion rate based on the currency code
      const conversionRate = this._exchangeRate?.[baseCurrency];
      if (conversionRate) {
        position.conversionRate = 1 / conversionRate;
        //Convert pnl to "USD"
        position.pnl = position.pnl * position.conversionRate;
      }
    }
    position.pnl = Number(position.pnl);
    if (
      this._positionIdAndPnlMap.get(positionId) !== position.pnl ||
      this._positionIdAndPnlMap.get(positionId) === undefined
    ) {
      this._positionIdAndPnlMap.set(positionId, position.pnl);
      this._host.positionPartialUpdate(positionId, {
        pnl: position.pnl,
        last: position.last,
      });
      this._host.plUpdate(position.pnl);
      this._updateAccountInfo();
    }
  }

  _deletePosition(position) {
    const sPosition = this._positionById[position.id];
    if (sPosition) {
      delete this._positionById[sPosition.id];
      this._positions = this._positions.filter((cp) => cp.id != position.id);
      this._host.positionUpdate(position);
      const subscribe = new Promise((resolve) => {
        this._quotesProvider.unsubscribeQuotes(`_position_${position.id}`);
        logMessage('_deletePosition: unsubscribeQuotes for ', position.id);
        resolve();
      });
      this._positionIdAndPnlMap.delete(position.id);
    }
  }

  _createOrUpdateExecution(execution) {
    this._host.executionUpdate(execution);
  }

  async _fetchOpenOrders() {
    if (!this.userLoggedIn) return;
    try {
      const res = await fetch(
        `${API_URL}/api/accounts/${this.userID}/orders/open`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
          },
        },
      );
      if (res.ok) {
        let orders = await res.json();
        orders = orders.d;
        orders.map((order) => {
          order = this._convertOrderToTVOrder(order);
          this._orderById[order.id] = order;
        });
        this._orders = orders;
        logMessage('_fetchOpenOrders: Orders => ', orders);
      } else if (!res.ok && res.status === 403) {
        this._logoutAccountWhenForbidden();
      }
    } catch (e) {
      logErrorMessage(
        '_fetchOpenOrders: Error occured while fetching orders ',
        e,
      );
    }
  }

  async _fetchClosedOrders() {
    return new Promise(async (resolve, reject) => {
      if (!this.userLoggedIn) return resolve([]);
      try {
        const res = await fetch(
          `${API_URL}/api/accounts/${this.userID}/orders/closed`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.token}`,
            },
          },
        );
        if (res.ok) {
          let orders = await res.json();
          orders = orders.d;
          orders.map((order) => {
            order = this._convertOrderToTVOrder(order);
          });
          logMessage('_fetchClosedOrders: Orders => ', orders);
          resolve(orders);
        } else if (!res.ok && res.status === 403) {
          this._logoutAccountWhenForbidden();
        }
      } catch (e) {
        logErrorMessage(
          '_fetchClosedOrders: Error occured while fetching orders ',
          e,
        );
        reject(e);
      }
    });
  }

  async _fetchPositions() {
    if (!this.userLoggedIn) return;
    try {
      const res = await fetch(
        `${API_URL}/api/accounts/${this.userID}/positions`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
          },
        },
      );
      if (res.ok) {
        let positions = await res.json();
        positions = positions.d;
        positions.map((position) => {
          position = this._convertPositionToTVPosition(position);
          this._positionById[position.id] = position;
          const symbolInfo = getSymbol(position.symbol);
          this._positionIdAndPnlMap.set(position.id, position.pnl);
          const subscribe = new Promise((resolve) => {
            this._quotesProvider.subscribeQuotes(
              [position.symbol],
              [position.symbol],
              (quote) =>
                this._calculatePnlAndSetAccountInformation(
                  quote,
                  position.id,
                  symbolInfo,
                ),
              `_position_${position.id}`,
            );
            resolve();
          });
        });
        this._positions = positions;
        this._updateAccountInfo();
        logMessage('_fetchPositions: Positions => ', positions);
      } else if (!res.ok && res.status === 403) {
        this._logoutAccountWhenForbidden();
      }
    } catch (e) {
      logErrorMessage(
        '_fetchPositions: Error occured while fetching positions ',
        e,
      );
    }
  }

  _fetchExecutions(symbol) {
    return new Promise(async (resolve, reject) => {
      if (!this.userLoggedIn) return resolve([]);
      try {
        const res = await fetch(
          `${API_URL}/api/accounts/${this.userID}/executions?symbol=${symbol}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.token}`,
            },
          },
        );
        if (res.ok) {
          let executions = await res.json();
          executions = executions.d;
          executions.map((execution) => {
            execution = this._convertExecutionToTVExecution(execution);
          });
          logMessage('_fetchExecutions: Executions => ', executions);
          resolve(executions);
        } else if (!res.ok && res.status === 403) {
          this._logoutAccountWhenForbidden();
        }
      } catch (e) {
        logErrorMessage(
          '_fetchExecutions: Error occured while fetching executions ',
          e,
        );
        reject(e);
      }
    });
  }

  async _fetchAccountInfo() {
    if (!this.userLoggedIn) return;
    try {
      const res = await fetch(`${API_URL}/api/accounts/${this.userID}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      });
      if (res.ok) {
        let accountInfo = await res.json();
        this._accountManagerData.balance = accountInfo.d.balance;
        this._accountManagerData.pl = accountInfo.d.pnl;
        this._accountManagerData.equity = accountInfo.d.equity;
        this._accountCurrency = accountInfo.d.accountCurrency;
        this._currencySign = getcurrencySign(this._accountCurrency);
        await this._fetchExchangeRate();
        this._updateAccountInfo();
      } else if (!res.ok && res.status === 403) {
        this._logoutAccountWhenForbidden();
      }
    } catch (e) {
      logErrorMessage(
        'fetchAccountInfo: Error occured while fetching account info ',
        e,
      );
    }
  }

  _fetchAccountHistory() {
    return new Promise(async (resolve, reject) => {
      if (!this.userLoggedIn) return resolve([]);
      fetch(`${API_URL}/api/accounts/${this.userID}/history`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (!res.ok && res.status === 403) {
            this._logoutAccountWhenForbidden();
            reject(res.errmsg);
          } else {
            const histories = res.d;
            histories.map((history) => {
              history = this._convertAccountHistoryToTVAccountHistory(history);
            });
            logMessage('_fetchAccountHistory: Account history => ', histories);
            resolve(histories);
          }
        })
        .catch((e) => {
          logErrorMessage(`_fetchAccountHistory: ${getErrorMessage(e)}`, e);
          reject(e);
        });
    });
  }

  connectionStatus() {
    return this.userLoggedIn ? 1 : 0;
  }

  chartContextMenuActions(context, options) {
    return this._host.defaultContextMenuActions(context);
  }

  isTradable(symbol) {
    return Promise.resolve(true);
  }

  placeOrder(preOrder) {
    if (!this.userLoggedIn) return {};
    return new Promise((resolve, reject) => {
      logMessage('placeOrder: Place Order =>', preOrder);
      this._host.activateBottomWidget();
      const { isValidQty, message } = hasTwoOrFewerDecimalPlaces(preOrder.qty);
      if (!isValidQty) {
        reject(message);
        return;
      }
      if (!this._isWebSocketConnected()) {
        reject('Event Synchronization Stopped');
        return window.showBrokerEventSocketDisconnectModal();
      }
      let orderBody = {
        symbol: preOrder.symbol,
        qty: multiply(preOrder.qty, 10000),
        side: preOrder.side === -1 ? 'sell' : 'buy',
        type:
          preOrder.type == 1 ? 'limit' : preOrder.type == 3 ? 'stop' : 'market',
        price: String(preOrder.seenPrice),
        limitPrice: preOrder.limitPrice
          ? String(preOrder.limitPrice)
          : undefined,
        stopPrice: preOrder.stopPrice ? String(preOrder.stopPrice) : undefined,
        durationType:
          preOrder.duration && preOrder.duration.type
            ? preOrder.duration.type
            : undefined,
        durationDateTime:
          preOrder.duration && preOrder.duration.datetime
            ? preOrder.duration.datetime
            : undefined,
        stopLoss: preOrder.stopLoss ? String(preOrder.stopLoss) : undefined,
        takeProfit: preOrder.takeProfit
          ? String(preOrder.takeProfit)
          : undefined,
      };
      fetch(`${API_URL}/api/accounts/${this.userID}/orders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
        body: JSON.stringify(orderBody),
      })
        .then((res) => {
          if (res.status === 403) {
            this._logoutAccountWhenForbidden();
          }
          return res.json();
        })
        .then((res) => {
          if (res && res.s && res.s === 'error') {
            reject(res.errmsg);
          } else {
            resolve(res.d);
          }
        })
        .catch((e) => {
          logErrorMessage(`placeOrder: ${getErrorMessage(e)}`, e);
          reject(e);
        });
    });
  }

  modifyOrder(order) {
    if (!this.userLoggedIn) return;
    return new Promise((resolve, reject) => {
      logMessage('modifyOrder: Update order => ', order);
      let orderId = order.id;
      let originalOrder = this._orderById[orderId];
      let url;
      let body;
      const isSLOrder = orderId.indexOf('s') > 0;
      const isTPOrder = orderId.indexOf('t') > 0;
      if (!this._isWebSocketConnected()) {
        reject('Event Synchronization Stopped');
        return window.showBrokerEventSocketDisconnectModal();
      }
      if (isSLOrder || isTPOrder) {
        orderId = orderId.substr(0, orderId.length - 1);
        const position = this._positionById[orderId];
        if (position) {
          const stopLoss = isTPOrder
            ? position.stopLoss
            : order.stopPrice
            ? order.stopPrice
            : undefined;
          const takeProfit = isSLOrder
            ? position.takeProfit
            : order.limitPrice
            ? order.limitPrice
            : undefined;
          url = `${API_URL}/api/accounts/${this.userID}/positions/${position.id}`;
          body = {
            symbol: position.symbol,
            stopLoss: stopLoss ? String(stopLoss) : null,
            takeProfit: takeProfit ? String(takeProfit) : null,
          };
          logMessage('modifyOrder: Modify position => ', body);
        } else {
          originalOrder = this._orderById[orderId];
          if (originalOrder && originalOrder.status === 6) {
            url = `${API_URL}/api/accounts/${this.userID}/orders/${orderId}`;
            body = {
              qty: multiply(originalOrder.qty, 10000),
            };
            if (isSLOrder) {
              body.stopLoss = order.stopLoss
                ? String(order.stopLoss)
                : order.stopPrice
                ? String(order.stopPrice)
                : null;
            }
            if (isTPOrder) {
              body.takeProfit = order.takeProfit
                ? String(order.takeProfit)
                : order.limitPrice
                ? String(order.limitPrice)
                : null;
            }
            logMessage('modifyOrder: Modify order => ', body);
          }
        }
      } else if (originalOrder && originalOrder.status === 6) {
        url = `${API_URL}/api/accounts/${this.userID}/orders/${orderId}`;
        body = {
          qty: multiply(order.qty, 10000),
          limitPrice: order.limitPrice ? String(order.limitPrice) : null,
          stopPrice: order.stopPrice ? String(order.stopPrice) : null,
          durationType: order.duration.type,
          durationDateTime: order.duration.datetime,
          stopLoss: order.stopLoss ? String(order.stopLoss) : null,
          takeProfit: order.takeProfit ? String(order.takeProfit) : null,
        };
        logMessage('modifyOrder: Modify order => ', body);
      }
      if (body) {
        fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
          },
          body: JSON.stringify(body),
        })
          .then((res) => {
            if (res.status === 403) {
              this._logoutAccountWhenForbidden();
            }
            return res.json();
          })
          .then((res) => {
            if (res && res.s && res.s === 'error') {
              reject(res.errmsg);
            } else {
              resolve();
            }
          })
          .catch((e) => {
            logErrorMessage(`modifyOrder: ${getErrorMessage(e)}`, e);
            reject(e);
          });
      } else {
        resolve();
      }
    });
  }

  editPositionBrackets(positionId, positionBrackets) {
    return new Promise((resolve, reject) => {
      logMessage('editPositionBrackets: Edit position', positionBrackets);
      const position = this._positionById[positionId];
      if (!this._isWebSocketConnected()) {
        reject('Event Synchronization Stopped');
        return window.showBrokerEventSocketDisconnectModal();
      }
      if (position) {
        const positionBody = {
          symbol: position.symbol,
          stopLoss: positionBrackets.stopLoss
            ? String(positionBrackets.stopLoss)
            : null,
          takeProfit: positionBrackets.takeProfit
            ? String(positionBrackets.takeProfit)
            : null,
        };
        fetch(
          `${API_URL}/api/accounts/${this.userID}/positions/${positionId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.token}`,
            },
            body: JSON.stringify(positionBody),
          },
        )
          .then((res) => {
            if (res.status === 403) {
              this._logoutAccountWhenForbidden();
            }
            return res.json();
          })
          .then((res) => {
            if (res && res.s && res.s === 'error') {
              reject(res.errmsg);
            } else {
              resolve();
            }
          })
          .catch((e) => {
            logErrorMessage(`editPositionBrackets: ${getErrorMessage(e)}`, e);
            reject(e);
          });
      } else {
        resolve();
      }
    });
  }

  closePosition(positionId, qty = 0) {
    return new Promise((resolve, reject) => {
      const position = this._positionById[positionId];
      if (!this._isWebSocketConnected()) {
        reject('Event Synchronization Stopped');
        return window.showBrokerEventSocketDisconnectModal();
      }
      if (position) {
        const { isValidQty, message } = hasTwoOrFewerDecimalPlaces(qty);
        if (!isValidQty) {
          reject(message);
          return;
        }
        fetch(
          `${API_URL}/api/accounts/${this.userID}/positions/${positionId}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.token}`,
            },
            body: JSON.stringify({ qty: multiply(qty, 10000) }),
          },
        )
          .then((res) => {
            if (res.status === 403) {
              this._logoutAccountWhenForbidden();
            }
            return res.json();
          })
          .then((res) => {
            if (res && res.s && res.s === 'error') {
              reject(res.errmsg);
            } else {
              resolve();
            }
          })
          .catch((e) => {
            logErrorMessage(`closePosition: ${getErrorMessage(e)}`, e);
            reject(e);
          });
      } else {
        resolve();
      }
    });
  }

  async orders() {
    logMessage('orders: Get orders');
    await this._fetchOpenOrders();
    return Promise.resolve(this._orders.slice());
  }

  async ordersHistory() {
    logMessage('ordersHistory: Get orders history');
    return this._fetchClosedOrders();
  }

  async positions() {
    logMessage('positions: Get positions');
    await this._fetchPositions();
    return Promise.resolve(this._positions.slice());
  }

  async executions(symbol) {
    logMessage(`executions: Get executions for symbol ${symbol}`);
    return this._fetchExecutions(symbol);
  }

  async cancelOrder(orderId) {
    return new Promise((resolve, reject) => {
      const isSLOrder = orderId.indexOf('s') > 0;
      const isTPOrder = orderId.indexOf('t') > 0;
      if (!this._isWebSocketConnected()) {
        reject('Event Synchronization Stopped');
        return window.showBrokerEventSocketDisconnectModal();
      }
      if (isSLOrder || isTPOrder) {
        this.modifyOrder({
          id: orderId,
        })
          .then(() => resolve())
          .catch((e) => reject(e));
      } else {
        fetch(`${API_URL}/api/accounts/${this.userID}/orders/${orderId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
          },
          method: 'DELETE',
        })
          .then((res) => {
            if (res.status === 403) {
              this._logoutAccountWhenForbidden();
            }
            return res.json();
          })
          .then((res) => {
            if (res && res.s && res.s === 'error') {
              reject(res.errmsg);
            } else {
              resolve();
            }
          })
          .catch((e) => {
            logErrorMessage(`cancelOrder: ${getErrorMessage(e)}`, e);
            reject(e);
          });
      }
    });
  }

  accountManagerInfo() {
    const summaryProps = [
      {
        text: 'Account Balance',
        wValue: this._balanceValue,
        formatter: 'text' /* StandardFormatterName.Fixed */,
        isDefault: true,
      },
      {
        text: 'Equity',
        wValue: this._equityValue,
        formatter: 'text' /* StandardFormatterName.Fixed */,
        isDefault: true,
      },
      {
        text: 'P&L',
        wValue: this._plValue,
        formatter: 'text' /* StandardFormatterName.Fixed */,
        isDefault: true,
      },
    ];
    return {
      accountTitle: 'Trading Sample',
      summary: summaryProps,
      orderColumns: ordersPageColumns,
      positionColumns: positionsPageColumns,
      historyColumns: historyPageColumns,
      pages: [
        {
          id: 'accountHistory',
          title: 'Account History',
          tables: [
            {
              id: 'history',
              columns: accountHistoryPageColumns,
              getData: () => {
                return this._fetchAccountHistory();
              },
              initialSorting: {
                property: 'closeTime',
                asc: false,
              },
              changeDelegate: this._ahChangeDelegate,
            },
          ],
        },
      ],
      contextMenuActions: (contextMenuEvent, activePageActions) => {
        return Promise.resolve(this._bottomContextMenuItems(activePageActions));
      },
    };
  }

  async symbolInfo(symbol) {
    const sym = getSymbol(symbol);
    const isForex = sym && convertType(sym.type) === 'forex';
    const mintick = await this._host.getSymbolMinTick(symbol);
    const pipSize = isForex
      ? Math.max(this._pipSizeForForex(symbol), mintick)
      : mintick; // pip size can differ from minTick
    const pointValue = 1; // USD value of 1 point of price
    const accountCurrencyRate =
      pointValue / (this._exchangeRate[sym.currency_code] || 1); // account currency rate
    let qty = {
      max: sym.qty && sym.qty.max ? sym.qty.max : 1e5,
      min: sym.qty && sym.qty.min ? sym.qty.min : 1,
      step: sym.qty && sym.qty.step ? sym.qty.step : 1,
      uiStep: sym.qty && sym.qty.step ? sym.qty.step : 1,
      default: sym.qty && sym.qty.default ? sym.qty.default : 1,
    };
    const symbolInfo = {
      qty,
      pipValue: pipSize * pointValue * accountCurrencyRate || 1,
      pipSize: pipSize,
      minTick: mintick,
      description: sym ? sym.description : '',
      type: sym ? convertType(sym.type) : '',
      currency: this._accountCurrency,
    };
    if (sym.contract_size) {
      symbolInfo.lotSize = sym.contract_size;
    }
    logMessage(`symbolInfo: ${symbol} => `, symbolInfo);
    return symbolInfo;
  }

  _pipSizeForForex(symbol) {
    return symbol.indexOf('JPY') === symbol.length - 3 ? 0.01 : 1e-4;
  }

  currentAccount() {
    return this.userID;
  }

  async accountsMetainfo() {
    return [
      {
        id: this.userID,
        name: this.userID,
        currencySign: this._currencySign,
        currency: this._accountCurrency,
      },
    ];
  }

  _bottomContextMenuItems(activePageActions) {
    const separator = { separator: true };
    const sellBuyButtonsVisibility = this._host.sellBuyButtonsVisibility();
    if (activePageActions.length) {
      activePageActions.push(separator);
    }
    return activePageActions.concat([
      {
        text: 'Show Buy/Sell Buttons',
        action: () => {
          if (sellBuyButtonsVisibility) {
            sellBuyButtonsVisibility.setValue(
              !sellBuyButtonsVisibility.value(),
            );
          }
        },
        checkable: true,
        checked:
          sellBuyButtonsVisibility !== null && sellBuyButtonsVisibility.value(),
      },
      {
        text: 'Trading Settings...',
        action: () => {
          this._host.showTradingProperties();
        },
      },
    ]);
  }

  _buttonDropdownItems() {
    const defaultActions = this._host.defaultDropdownMenuActions();
    return defaultActions.concat([
      {
        text: 'Trading Settings...',
        action: () => {
          this._host.showTradingProperties();
        },
      },
    ]);
  }

  _convertOrderToTVOrder(order) {
    order.side = order.side === 'buy' ? 1 : -1;
    if (order.type === 'limit') order.type = 1;
    else if (order.type === 'market') order.type = 2;
    else if (order.type === 'stop') order.type = 3;
    if (order.status === 'canceled') {
      order.status = 1;
      if (order.type === 1) {
        order.price = order.limitPrice;
      } else if (order.type === 3) {
        order.price = order.stopPrice;
      }
    } else if (order.status === 'filled') {
      order.status = 2;
      // limit
      if (order.type === 1) {
        order.price = order.limitPrice;
      }
      // market
      else if (order.type === 2) {
        order.price = order.price;
      }
      // stop
      else if (order.type === 3) {
        order.price = order.stopPrice;
      }
      order.fillPrice = order.price;
    } else if (order.status === 'inactive') order.status = 3;
    else if (order.status === 'placing') order.status = 4;
    else if (order.status === 'rejected') order.status = 5;
    else if (order.status === 'working') order.status = 6;
    if (order.durationType) {
      order.duration = {
        type: order.durationType,
      };
      delete order.durationType;
    }
    if (order.createTime) {
      order.createTime = formatDateTimeWithTimezone(
        order.createTime,
        this._currentUtcTimezone,
      );
    }
    if (order.closingTime) {
      order.closingTime = formatDateTimeWithTimezone(
        order.closingTime,
        this._currentUtcTimezone,
      );
    }
    order.qty /= 10000;
    if (order.commission !== undefined) {
      order.commission = `${this._currencySign} ${parseFloat(
        order.commission,
      ).toFixed(2)}`;
    }
    return order;
  }

  _convertPositionToTVPosition(position) {
    if (position.side === 'buy') position.side = 1;
    else if (position.side === 'sell') position.side = -1;
    position.createTime = formatDateTimeWithTimezone(
      position.createTime,
      this._currentUtcTimezone,
    );
    position.avgPrice = position.price;
    if (position.commission !== undefined) {
      position.commission = `${this._currencySign} ${parseFloat(
        position.commission,
      ).toFixed(2)}`;
    }
    position.qty /= 10000;
    return position;
  }

  _convertExecutionToTVExecution(execution) {
    if (execution.side === 'buy') execution.side = 1;
    else if (execution.side === 'sell') execution.side = -1;
    execution.time = new Date(execution.time).getTime();
    execution.qty /= 10000;
    return execution;
  }

  _convertAccountHistoryToTVAccountHistory(accountHistory) {
    if (!accountHistory.symbol) {
      accountHistory.symbol = '';
    }
    if (accountHistory.commission !== undefined) {
      accountHistory.commission = `${this._currencySign} ${parseFloat(
        accountHistory.commission,
      ).toFixed(2)}`;
    }
    if (accountHistory.initialQty && accountHistory.closeQty) {
      accountHistory.qty = `${accountHistory.closeQty / 10000}/${
        accountHistory.initialQty / 10000
      }`;
    }
    accountHistory.openTime = formatDateTimeWithTimezone(
      accountHistory.openTime,
      this._currentUtcTimezone,
    );
    accountHistory.closeTime = formatDateTimeWithTimezone(
      accountHistory.closeTime,
      this._currentUtcTimezone,
    );
    return accountHistory;
  }

  async _fetchExchangeRate() {
    if (!this.userLoggedIn) return;
    try {
      const res = await fetch(
        `${API_URL}/api/accounts/${this.userID}/exchange-rate`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
          },
        },
      );
      if (res.ok) {
        let data = await res.json();
        this._exchangeRate = data.d[this._accountCurrency]?.rates;
        logMessage('_fetchExchangeRate: ExchangeRate => ', this._exchangeRate);
      } else if (!res.ok && res.status === 403) {
        this._logoutAccountWhenForbidden();
      }
    } catch (e) {
      logErrorMessage(
        '_fetchExchangeRate: Error occured while fetching exchangeRate ',
        e,
      );
    }
  }

  _connectWebSocket() {
    try {
      if (!this.userLoggedIn) return;

      this.socket = io(API_URL, {
        reconnectionDelayMax: 10000,
        auth: {
          token: this.token,
        },
      });

      this.socket.on('connect', () => {
        logMessage('_connectWebSocket: Web socket connection established.');
      });

      this.socket.on('disconnect', (event) => {
        logMessage('_connectWebSocket: Web socket Disconnected !', event);
        if (event !== 'io client disconnect' && event !== 'transport close') {
          window.showSessionDisconnectedModal();
        }
      });

      this.socket.onAny((event, ...args) => {
        logMessage(`_connectWebSocket: Event ${event}`, ...args);
      });

      this.socket.on('token_expired', (event) => {
        logMessage('_connectWebSocket: Token expired', event);
        this.socket.disconnect();
        this._logoutAccountWhenForbidden();
      });

      this.socket.on('order', (event) => {
        const order = this._convertOrderToTVOrder(event);
        this._createOrUpdateOrder(order);
        if (order.status === 1 || order.status === 2 || order.status === 6) {
          let stopLoss = undefined;
          let takeProfit = undefined;
          let updateBracketOrders = true;
          if (order.status !== 1) {
            if (order.status === 6) {
              stopLoss = order.stopLoss;
              takeProfit = order.takeProfit;
            } else if (order.status === 2) {
              stopLoss = order.reason === 'sl' ? order.price : undefined;
              takeProfit = order.reason === 'tp' ? order.price : undefined;
              if (!order.reason) {
                const position = this._positionById[order.positionId];
                if (position) {
                  updateBracketOrders = false;
                } else {
                  stopLoss = order.stopLoss;
                  takeProfit = order.takeProfit;
                }
              }
            }
          }
          if (updateBracketOrders) {
            this._createOrUpdateBracketOrder(
              order.positionId ? order.positionId : order.id,
              order.symbol,
              order.qty,
              order.side,
              stopLoss,
              takeProfit,
              order.status === 6 ? 3 : 6,
              order.createTime,
              order.reason ? order.price : undefined,
              order.closingTime,
            );
          }
        }
      });
      this.socket.on('position', (event) => {
        const position = this._convertPositionToTVPosition(event);
        if (position.qty === 0) {
          this._deletePosition(position);
        } else {
          this._createOrUpdatePosition(position);
        }
      });
      this.socket.on('execution', (event) => {
        this._accountManagerData.balance =
          parseFloat(this._accountManagerData.balance) +
          parseFloat(event.pnl) +
          parseFloat(event.swap) +
          parseFloat(event.commission);
        this._updateAccountInfo();
        const execution = this._convertExecutionToTVExecution(event);
        this._createOrUpdateExecution(execution);
      });
      this.socket.on('accountHistory', (event) => {
        const accountHistory =
          this._convertAccountHistoryToTVAccountHistory(event);
        this._ahChangeDelegate.fire(accountHistory);
      });
      this.socket.on('exchangeRate', (event) => {
        this._exchangeRate = event[this._accountCurrency]?.rates;
      });
    } catch (e) {
      logErrorMessage(
        '_connectWebSocket: error occured while connecting webSocket',
        e,
      );
    }
  }

  _disconnectWebSocket() {
    this.socket.disconnect();
    for (const [positionId, pnl] of this._positionIdAndPnlMap) {
      const subscribe = new Promise((resolve) => {
        this._quotesProvider.unsubscribeQuotes(`_position_${positionId}`);
        resolve();
      });
    }
    this._positionIdAndPnlMap = new Map();
  }

  _isWebSocketConnected() {
    return this.socket && this.socket.connected;
  }

  /**
   * Scheduler for fetching open positions and swapping charges if conditions are met
   * every start of the day + 5 seconds.
   */
  _scheduleSwapChargeUpdate() {
    const currentTime = moment().utc().valueOf();
    const nextStartOfTheDayTime = moment()
      .utc()
      .add(1, 'days')
      .startOf('day')
      .add(5, 'seconds')
      .valueOf();
    const intervalId = setInterval(() => {
      if (this._positions.length > 0) {
        window.swapChargeRefreshModel(); // Show swap charge refresh Model and refresh page
        clearInterval(intervalId);
      } else {
        clearInterval(intervalId);
        this._scheduleSwapChargeUpdate(); // Retry setting interval if no positions are open
      }
    }, nextStartOfTheDayTime - currentTime);
  }

  async loginAccount(userID, token) {
    this.userLoggedIn = true;
    this.userID = userID;
    this.token = token;
    this.sessionId = generateUUID();
    await this._fetchAccountInfo();
    this._connectWebSocket();
    this._host._trading.toggleTradingWidget();
    this._host.connectionStatusUpdate(1);
    this._host.currentAccountUpdate();
    this._scheduleSwapChargeUpdate(); // Start the swap charge update scheduler
  }

  logoutAccount() {
    this.userLoggedIn = false;
    this.userID = '';
    this.token = '';
    this._disconnectWebSocket();
    this._host._trading.toggleMinimizeBottomWidgetBar();
    this._host.connectionStatusUpdate(0);
    this._balanceValue.setValue(0);
    this._equityValue.setValue(0);
    this._plValue.setValue(0);
    this._host.currentAccountUpdate();
  }

  getHost() {
    return this._host;
  }
}
